import React from "react";
import { Trans } from "@lingui/macro";
import Layout from "~/components/layout";
import Link from "~/components/link";

const NotFoundPage = (props) => {
  const paths = props.pageContext.paths;

  return (
    <Layout paths={paths}>
      <div className="text-center p-16">
        <div className="inline-flex flex-col md:flex-row md:items-end">
          <img
            src="https://res.cloudinary.com/ie1/image/upload/f_auto,fl_progressive:steep,q_auto:best/v1604073629/vtutor/404_qwiqnh.png"
            alt="404"
            className="inline-block"
            style={{
              width: 556,
            }}
          />
          <div className="inline-block leading-none md:ml-10">
            <div
              className="text-brand-link-gray tracking-tighter"
              style={{
                fontSize: "8em",
              }}
            >
              404
            </div>
            <div
              className="font-semibold tracking-wide"
              style={{
                fontSize: "1.7em",
              }}
            >
              &middot; <Trans>NOT FOUND</Trans> &middot;
            </div>
          </div>
        </div>
        <div className="mt-10">
          <p className="text-xl mb-3">
            <Trans>Sorry we couldn't find the page you're looking for.</Trans>
          </p>
          <p className="mb-2">
            <Trans>Go back to all courses:</Trans>
          </p>
          <Link
            to="/courses"
            className="no-underline btn btn-primary py-2 xl:py-3 inline-block w-full md:w-1/3 md:text-2xl"
          >
            <span className="mr-2 align-middle lg:tracking-wide">
              <Trans>View all courses</Trans>
            </span>
            <span className="icon-chevron-right text-xs ml-2 font-bold" />
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
